const transfer_automation = () => {
  $(document).off('focus', '#expense_manager_transfer_amount_to');
  $(document).on('focus', '#expense_manager_transfer_amount_to', () => {
    var from_amount = $('#expense_manager_transfer_amount_from').val();
    $('#expense_manager_transfer_amount_to').val(from_amount);
  });
};

const mutation_automation = () => {
  const auto_amount = (obj_name) => {
    var amount, description, descriptions, i, len, perc, prev_amount, sum;
    descriptions = $('#expense_manager_' + obj_name + '_description')
      .val()
      .split('\n');
    prev_amount = parseFloat($('#expense_manager_' + obj_name + '_abs_amount').val());
    sum = 0;
    for (i = 0, len = descriptions.length; i < len; i++) {
      description = descriptions[i];
      if ((perc = description.match(/^-?\d+(\.\d+)?%/))) {
        sum += (parseFloat(perc) / 100.0) * sum;
      } else if ((amount = description.match(/^-?\d+(\.\d+)?/))) {
        sum += parseFloat(amount);
      }
    }
    sum = Math.round(sum * 100) / 100;
    $('#expense_manager_' + obj_name + '_abs_amount').val(sum);
    if (prev_amount > 0 && prev_amount !== sum) {
      $('#amount_list').prepend('<p>Previous amount: ' + prev_amount + '</p>');
    }
  };

  $(document).off('focus', '#expense_manager_mutation_abs_amount');
  $(document).on('focus', '#expense_manager_mutation_abs_amount', () => {
    auto_amount('mutation');
  });

  $(document).off('focus', '#expense_manager_credit_abs_amount');
  $(document).on('focus', '#expense_manager_credit_abs_amount', () => {
    auto_amount('credit');
  });

  $(document).off('focus', '#expense_manager_debit_abs_amount');
  $(document).on('focus', '#expense_manager_debit_abs_amount', () => {
    auto_amount('debit');
  });
};

const autocompletes = () => {
  const accounts = '/expense_manager/accounts.json';
  const debt_categories = '/expense_manager/debt_categories.json';
  const aites = '/expense_manager/aites.json';
  const categories = '/expense_manager/categories.json';
  const budgets = '/expense_manager/budgets.json';

  // transfer form
  $('#expense_manager_transfer_from')?.autocomplete({ source: accounts });
  $('#expense_manager_transfer_to')?.autocomplete({ source: accounts });

  // debt form
  $('#expense_manager_debt_debt_category')?.autocomplete({
    source: debt_categories
  });
  $('#expense_manager_debt_aite')?.autocomplete({ source: aites });

  // scheduled mutation form
  $('#expense_manager_scheduled_mutation_category')?.autocomplete({
    source: categories
  });
  $('#expense_manager_scheduled_mutation_aite')?.autocomplete({
    source: aites
  });

  // mutation form
  $('#expense_manager_mutation_category')?.autocomplete({ source: categories });
  $('#expense_manager_credit_category')?.autocomplete({ source: categories });
  $('#expense_manager_debit_category')?.autocomplete({ source: categories });
  $('.category')?.autocomplete({ source: categories });

  $('#expense_manager_mutation_aite')?.autocomplete({ source: aites });
  $('#expense_manager_credit_aite')?.autocomplete({ source: aites });
  $('#expense_manager_debit_aite')?.autocomplete({ source: aites });
  $('.aite')?.autocomplete({ source: aites });

  $('#expense_manager_mutation_account')?.autocomplete({ source: accounts });
  $('#expense_manager_credit_account')?.autocomplete({ source: accounts });
  $('#expense_manager_debit_account')?.autocomplete({ source: accounts });
  $('.account')?.autocomplete({ source: accounts });

  $('#expense_manager_mutation_budget')?.autocomplete({ source: budgets });
  $('#expense_manager_credit_budget')?.autocomplete({ source: budgets });
  $('#expense_manager_debit_budget')?.autocomplete({ source: budgets });
  $('.budget')?.autocomplete({ source: budgets });
};

const scripts = () => {
  transfer_automation();
  mutation_automation();
  autocompletes();
  // $('#expense_manager_mutation_datetime').datepicker()
};

document.addEventListener('DOMContentLoaded', () => {
  scripts();
});
